import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import SignatureForm from 'components/SignatureForm.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`Use this tool to generate your email signature and then follow the instructions below to install it on your preferred email client.`}</MDXTag>
<div className="notice">
  As mentioned in the Typography guide, emails should be composed using Arial.
</div>

<SignatureForm/>
<MDXTag name="h2" components={components}>{`Instructions`}</MDXTag>
<MDXTag name="p" components={components}>{`If your email client is not listed here, please contact a team member from Design or Marketing.`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.daretothink.co.uk/html-email-signature-in-apple-mail/"}}>{`Apple Mail`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.exclaimer.com.au/email-signature-handbook/10035-html-iphone-email-signature"}}>{`Apple Mail for iOS`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.extendoffice.com/documents/outlook/3847-outlook-import-insert-html-signatures.html"}}>{`Microsoft Outlook`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"https://support.google.com/mail/answer/8395?co=GENIE.Platform%3DAndroid&hl=en"}}>{`Gmail for Android`}</MDXTag></MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  
import React from 'react';

import {BrandConsumer} from 'components/Layout.js';
import Graphic from 'components/Graphic.js';
import SignatureOutput from 'components/SignatureOutput.js';
import SignaturePreview from 'components/SignaturePreview.js';


export default class SignatureForm extends React.Component {
    constructor() {
        super();
        this.state = {
            awardsBanner: true,
            landline: '',
            name: 'Rami Weiss',
            phone: '',
            qualifications: '',
            switchPhone: false,
            title: '',
        };
        this.handleAwardsBannerChange = this.handleAwardsBannerChange.bind(this);
        this.handleLandlineChange = this.handleLandlineChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleQualsChange = this.handleQualsChange.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
    }

    handleAwardsBannerChange() {
        this.setState({
            awardsBanner: !this.state.awardsBanner,
        });
    }

    handleLandlineChange(event) {
        this.setState({
            landline: event.target.value,
        });
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value,
        });
    }

    handlePhoneChange(event) {
        this.setState({
            phone: event.target.value,
        });
    }

    handleQualsChange(event) {
        this.setState({
            qualifications: event.target.value,
        });
    }

    handleSwitchChange() {
        this.setState({
            switchPhone: !this.state.switchPhone,
        });
    }

    handleTitleChange(event) {
        this.setState({
            title: event.target.value,
        });
    }

    render() {
        return (
            <BrandConsumer>
                {(context) => (
                    <form className="email-tool">
                        <fieldset>
                            <label>{'Full name'}</label>
                            <input
                                autoFocus={true}
                                onChange={this.handleNameChange}
                                placeholder="e.g. Rami Weiss"
                                type="text"
                                value={this.state.name}
                            />
                            <label>{'Title'}</label>
                            <input
                                onChange={this.handleTitleChange}
                                placeholder="e.g. CEO"
                                type="text"
                                value={this.state.title}
                            />
                            <label>
                                {'Qualifications'}
                                <em>{' \u2014 optional'}</em>
                            </label>
                            <input
                                onChange={this.handleQualsChange}
                                placeholder="e.g. BSc (Med Sci)"
                                type="text"
                                value={this.state.qualifications}
                            />
                            <label>
                                {'Mobile'}
                                <em>{' \u2014 Int. format e.g. +614 1196 9173‬'}</em>
                            </label>
                            <input
                                onChange={this.handlePhoneChange}
                                placeholder="+614 1196 9173‬"
                                type="text"
                                value={this.state.phone}
                            />
                            <label>
                                {'Direct landline'}
                                <em>{' \u2014 e.g. +612 9876 1234'}</em>
                            </label>
                            <input
                                onChange={this.handleLandlineChange}
                                placeholder="+612 9876 1234"
                                type="text"
                                value={this.state.landline}
                            />
                            <label className="bool">
                                <input
                                    checked={this.state.switchPhone}
                                    onChange={this.handleSwitchChange}
                                    type="checkbox"
                                />
                                <Graphic name="checkbox"/>
                                {'Include switch phone number'}
                            </label>
                            <label className="bool">
                                <input
                                    checked={this.state.awardsBanner}
                                    onChange={this.handleAwardsBannerChange}
                                    type="checkbox"
                                />
                                <Graphic name="checkbox"/>
                                {'Include awards banner'}
                            </label>
                        </fieldset>
                        <figure>
                            <SignaturePreview
                                awardsBanner={this.state.awardsBanner}
                                landline={this.state.landline}
                                name={this.state.name}
                                phone={this.state.phone}
                                qualifications={this.state.qualifications}
                                switchPhone={this.state.switchPhone}
                                title={this.state.title}
                            />
                            <SignatureOutput
                                awardsBanner={this.state.awardsBanner}
                                brand={context.brand}
                                landline={this.state.landline}
                                name={this.state.name}
                                phone={this.state.phone}
                                qualifications={this.state.qualifications}
                                switchPhone={this.state.switchPhone}
                                title={this.state.title}
                            />
                        </figure>
                    </form>
                )}
            </BrandConsumer>
        );
    }
}

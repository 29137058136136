/* eslint react-native/no-inline-styles: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import {BrandConsumer} from 'components/Layout.js';

import colors from 'data/colors.json';


export default class SignaturePreview extends React.Component {
    static propTypes = {
        awardsBanner: PropTypes.bool,
        landline: PropTypes.string,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string,
        qualifications: PropTypes.string,
        switchPhone: PropTypes.bool,
        title: PropTypes.string.isRequired,
    };

    render() {
        const linkStyle = {color: colors.blue.hex, textDecorationColor: 'currentColor'};
        const textStyle = {color: '#000000', margin: 0};
        const supportStyle = {color: '#808080', margin: 0};
        return (
            <BrandConsumer>
                {(context) => (
                    <section className="preview">
                        {this.props.name &&
                            <p style={textStyle}>
                                <strong>{this.props.name}</strong>
                            </p>
                        }
                        {this.props.qualifications &&
                            <p style={supportStyle}>
                                {this.props.qualifications}
                            </p>
                        }
                        {this.props.title &&
                            <p style={textStyle}>
                                {this.props.title}
                            </p>
                        }
                        {this.props.phone &&
                            <p style={textStyle}>
                                <a
                                    href={`tel:${this.props.phone.replace(/\s+/g, '')}`}
                                    style={linkStyle}
                                >
                                    {this.props.phone}
                                </a>
                            </p>
                        }
                        {this.props.landline &&
                            <p style={textStyle}>
                                {'Direct: '}
                                <a
                                    href={`tel:${this.props.landline.replace(/\s+/g, '')}`}
                                    style={linkStyle}
                                >
                                    {this.props.landline}
                                </a>
                            </p>
                        }
                        <p>{'\u0020'}</p>
                        <p style={{margin: '0'}}>
                            {context.brand === 'HealthShare' &&
                                <img
                                    alt="HealthShare"
                                    height="30"
                                    src="/email-signature-logo.png"
                                    style={{display: 'block'}}
                                    width="150"
                                />
                            }
                            {context.brand === 'BetterConsult' &&
                                <img
                                    alt="BetterConsult"
                                    height="30"
                                    src="/email-signature-logo-bc.png"
                                    style={{display: 'block'}}
                                    width="164"
                                />
                            }
                        </p>
                        <p>{'\u0020'}</p>
                        {this.props.switchPhone &&
                            <p style={textStyle}>
                                <a href="tel:1300533433" style={linkStyle}>
                                    {'1300 533 433'}
                                </a>
                            </p>
                        }
                        <p style={textStyle}>
                            {'Level 6, 1 Newland St'}
                            <br/>
                            {'Bondi Junction, NSW 2022 Australia'}
                            <br/>
                            <a href="https://www.healthsharedigital.com" style={linkStyle}>
                                {'healthsharedigital.com'}
                            </a>
                        </p>
                        {this.props.awardsBanner &&
                            <React.Fragment>
                                <p>{'\u0020'}</p>
                                <p style={{margin: '0'}}>
                                    <img
                                        alt="Deloitte Technology Fast 50 2018"
                                        height="36"
                                        src="/email-signature-award.png"
                                        style={{display: 'block'}}
                                        width="192"
                                    />
                                </p>
                            </React.Fragment>
                        }
                    </section>
                )}
            </BrandConsumer>
        );
    }
}


import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-html-to-clipboard';

import colors from 'data/colors.json';


export default class SignatureOutput extends React.Component {
    static propTypes = {
        awardsBanner: PropTypes.bool,
        brand: PropTypes.string.isRequired,
        landline: PropTypes.string,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string,
        qualifications: PropTypes.string,
        switchPhone: PropTypes.bool,
        title: PropTypes.string.isRequired,
    };

    render() {
        const linkStyle = `color: ${colors.blue.hex}; text-decoration-color: ${colors.blue.hex};`;
        const textStyle = `color: #000000; margin: 0;`;
        const supportStyle = `color: #808080; margin: 0;`;
        const signatureName = this.props.name ?
            `<p style="${textStyle}">
                <strong>${this.props.name}</strong>
            </p>`
            : ''
        ;
        const signatureQualifications = this.props.qualifications ?
            `<p style="${supportStyle}">${this.props.qualifications}</p>`
            : ''
        ;
        const signatureTitle = this.props.title ?
            `<p style="${textStyle}">${this.props.title}</p>`
            : ''
        ;
        const signaturePhone = this.props.phone ?
            `<p style="${textStyle}">
                <a href="tel:${this.props.phone.replace(/\s+/g, '')}" style="${linkStyle}">
                    ${this.props.phone}
                </a>
            </p>`
            : ''
        ;
        const signatureLandline = this.props.landline ?
            `<p style="${textStyle}">
                Direct: <a href="tel:${this.props.landline.replace(/\s+/g, '')}" style="${linkStyle}">
                    ${this.props.landline}
                 </a>
            </p>`
            : ''
        ;
        let logoSrc = 'email-signature-logo.png';
        let logoWidth = '149';
        if(this.props.brand === 'BetterConsult') {
            logoSrc = 'email-signature-logo-bc.png';
            logoWidth = '164';
        }
        const signatureLogo =
            `<p style="margin: 0;">
                <img
                    alt="${this.props.brand}"
                    height="30"
                    src="https://www.healthshare.design/${logoSrc}"
                    style="display: block;"
                    width="${logoWidth}"
                />
            </p>`
        ;
        const signatureSwitch = this.props.switchPhone ?
            `<p style="${textStyle}">
                <a href="tel:1300533433" style="${linkStyle}">
                    1300 533 433
                </a>
            </p>`
            : ''
        ;
        const signatureAddress =
            `<p style="${textStyle}">
                Level 6, 1 Newland St<br/>
                Bondi Junction, NSW 2022 Australia<br/>
                <a href="https://www.healthsharedigital.com/" style="${linkStyle}">
                    healthsharedigital.com
                </a>
            </p>`
        ;
        const signatureAwards = this.props.awardsBanner ?
            `<p style="margin: 0;">&nbsp;</p>
            <p style="margin: 0;">
                <img
                    alt="Deloitte Technology Fast 50 2018"
                    height="36"
                    src="https://www.healthshare.design/email-signature-award.png"
                    style="display: block;"
                    width="192"
                />
            </p>`
            : ''
        ;
        const signature = `
            <body>
                <br/><br/>
                ${signatureName}
                ${signatureQualifications}
                ${signatureTitle}
                ${signaturePhone}
                ${signatureLandline}
                <p style="margin: 0;">&nbsp;</p>
                ${signatureLogo}
                <p style="margin: 0;">&nbsp;</p>
                ${signatureSwitch}
                ${signatureAddress}
                ${signatureAwards}
                <style>
                    @media (prefers-color-scheme: dark) {
                        [src*="email-signature-logo"] {
                            filter: grayscale(100%) brightness(1000%);
                        }
                        p {
                            color: #FFF;
                        }
                        a {
                            color: ${colors.blue.steps[2].hex};
                        }
                    }
                </style>
            </body>
        `;
        return (
            <CopyToClipboard text={signature}>
                <button type="button">
                    {'Copy to clipboard'}
                </button>
            </CopyToClipboard>
        );
    }
}
